import React from 'react';
import { Button, Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import WithModalFormChecking from './WithModalFormChecking';

const Option = Select.Option;

const ImportSingle = Form.create({ name: 'import-single' })(
  WithModalFormChecking([ 'did', 'shared_secret', 'datafmt', 'granularity' ])(
  (props) => {
    const { getFieldDecorator } = props.form;
    return (
      <Form layout="vertical">
        <Form.Item label="Device ID">
          {getFieldDecorator('did', {
            rules: [{ required: true }]
          })(<Input placeholder=""/>)}
        </Form.Item>
        <Form.Item label="Shared key">
          {getFieldDecorator('shared_secret', {
            rules: [{
              required: true,
              pattern: /^[0-9a-fA-F]{64}$/,
              message: 'Enter a 32-byte hex-encoded value (64 characters)',
            }]
          })(<Input placeholder="0123456789abcdef0123456789abcdef0123456789abcdef0123456789abcdef"/>)}
        </Form.Item>
        <Form.Item label="Data format">
          {getFieldDecorator('datafmt', {
            rules: [{ required: true }]
          })(<Select>
            <Option key="sensor6">Sensor rev6 data</Option>
            <Option key="calibrator1">Calibrator plug rev1 data</Option>
            <Option key="power">Power (W)</Option>
            <Option key="energy_Ws">Energy (Ws, J)</Option>
            <Option key="energy_kWh">Energy (kWh)</Option>
          </Select>)}
        </Form.Item>
        <Form.Item label="Granularity">
          {getFieldDecorator('granularity', {
            rules: [{ required: true }]
          })(<Select>
            <Option key="5min">5min (e.g. sensor)</Option>
            <Option key="30min">30min (e.g. smart meter data)</Option>
            <Option key="hour">Hour</Option>
            <Option key="day">Day</Option>
          </Select>)}
        </Form.Item>
        <Form.Item>
          <Button
            {...props.okButtonProps}
            type="primary"
            onClick={props.onOk}
          >Import</Button>
        </Form.Item>
      </Form>
    );
  })
);

const WrappedImportSingle = (props) => {
    return (
      <div style={{width: '50em'}}>
        <ImportSingle onOk={(vals) => props.onImport([vals])}/>
      </div>
    );
}

export default WrappedImportSingle;
