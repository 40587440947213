import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Popconfirm, Select, message } from 'antd';
import { Form } from '@ant-design/compatible';
import {EditOutlined, PaperClipOutlined, DeleteOutlined} from '@ant-design/icons';
import ManagedPagedTable from './ManagedPagedTable';
import DeviceBinding from './DeviceBinding';
import WithModalFormChecking from './WithModalFormChecking';
import { useAuth } from '../context/auth';
import { apiGetTenants, apiGetAccounts, apiCreateAccount, apiUpdateAccount } from '../api/api';

const Option = Select.Option;
const trace_reference = {};

const AddAccount = Form.create({ name: 'add-account' })(
  WithModalFormChecking([ 'external_id', 'locale', 'timezone' ])(
  (props) => {
    const { getFieldDecorator } = props.form;
    return (
      <Modal
        { ...props }
        title="Add account"
        okText="Add"
      >
        <Form layout="vertical">
          <Form.Item label="Account name">
            {getFieldDecorator('external_id', {
              rules: [{ required: true }]
            })(<Input placeholder="Enter new account name"/>)}
          </Form.Item>
          <Form.Item label="Latitude (negative values = south)">
            {getFieldDecorator('lat', {})(<Input placeholder="-37.818"/>)}
          </Form.Item>
          <Form.Item label="Longitude (negative values = west)">
            {getFieldDecorator('lon', {})(<Input placeholder="144.962"/>)}
          </Form.Item>
          <Form.Item label="Locale">
            {getFieldDecorator('locale', {
              rules: [{ required: true }]
            })(<Input placeholder="en-au"/>)}
          </Form.Item>
          <Form.Item label="Time Zone">
            {getFieldDecorator('timezone', {
              rules: [{ required: true }]
            })(<Input placeholder="Australia/Melbourne"/>)}
          </Form.Item>
        </Form>
      </Modal>
    );
  })
);


const EditAccount = Form.create({ name: 'edit-account' })(
  WithModalFormChecking([ 'locale', 'timezone' ], true)(
  (props) => {
    const { getFieldDecorator } = props.form;
    const { record } = props;
    return (
      <Modal
        { ...props }
        destroyOnClose={true}
        title={`Edit account '${record.external_id}'`}
        okText="Apply"
      >
        <Form layout="vertical">
          <Form.Item label="Latitude (negative values = south)">
            {getFieldDecorator('lat', { initialValue: record.lat })(
              <Input placeholder="-37.818"/>)}
          </Form.Item>
          <Form.Item label="Longitude (negative values = west)">
            {getFieldDecorator('lon', { initialValue: record.lon })(
              <Input placeholder="144.962"/>)}
          </Form.Item>
          <Form.Item label="Locale">
            {getFieldDecorator('locale', {
              rules: [{ required: true }],
              initialValue: record.locale,
            })(<Input placeholder="en-AU"/>)}
          </Form.Item>
          <Form.Item label="Time Zone">
            {getFieldDecorator('timezone', {
              rules: [{ required: true }],
              initialValue: record.timezone,
            })(<Input placeholder="Australia/Melbourne"/>)}
          </Form.Item>
          <Form.Item label="Trace Config (do not enable frivolously!)">
            {getFieldDecorator('trace_config', {
              initialValue: record.trace_config
            })(<Input placeholder="something=1,other=99"/>)
            }
            <div>Known traces:</div>
            <Select defaultValue="-">
              <Option key="-" value="-"> -- for reference only -- </Option>
              {Object.keys(trace_reference).map(key =>
                <Option value={key} key={key}>
                  {key}: {trace_reference[key]}
                </Option>)
              }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  })
);


const AccountsPanel = (props) => {
  const [ dataVer, setDataVer ] = useState(0);
  const [ modalAdd, setModalAdd ] = useState(false);
  const [ working, setWorking ] = useState(false);
  const [ tenants, setTenants ] = useState([]);
  const [ tenant, setTenant ] = useState(null);

  const [ modalEdit, setModalEdit ] = useState(null);
  const [ modalAttach, setModalAttach ] = useState(null);

  const { jwt, logoutIf401 } = useAuth();

  useEffect(() => {
    apiGetTenants(jwt)
    .then(list => {
      setTenants(list.entries);
      setTenant(list.entries[0]);
      setDataVer(dataVer + 1);
    })
    .catch(logoutIf401)
    .catch(err => message.warning(`Failed to load data: ${err}`));
  }, []); // eslint-disable-line

  function doDelete(id) {
    message.error('Account deletion not currently supported');
  }

  function doAdd(vals) {
    setWorking(true);
    apiCreateAccount(jwt, { tenant: tenant.id, ...vals })
    .then(() => setDataVer(dataVer + 1))
    .catch(logoutIf401)
    .catch(err => message.warning(`Failed to create account: ${err}`))
    .finally(() => {
      setWorking(false);
      setModalAdd(false);
    });
  }

  function doUpdate(vals) {
    [ 'lat', 'lon' ].forEach(key => {
      if (vals[key] === '')
        vals[key] = null;
    });
    setWorking(true);
    // don't revert shown values while we're updating
    setModalEdit(Object.assign({}, modalEdit, vals));
    apiUpdateAccount(jwt, {
      tenant: tenant.id,
      external_id: modalEdit.external_id,
      ...vals })
    .then(() => setDataVer(dataVer + 1))
    .catch(logoutIf401)
    .catch(err => message.warning(`Failed to update account: ${err}`))
    .finally(() => {
      setWorking(false);
      setModalEdit(null);
    });
  }

  function filteredGetAccounts(jwt, qs) {
    if (tenant == null)
      return Promise.resolve({ entries: [] });
    const flt = `tenant=${tenant.id}`;
    return apiGetAccounts(jwt, qs == '' ? flt : `${qs}&${flt}`)
    .catch(logoutIf401);
  }

  const columns = [
    { title: 'Account', dataIndex: 'account', sorter: true, filterable: true, fixed: 'left' },
    { title: 'Lat', dataIndex: 'lat', sorter: true, filterable: true },
    { title: 'Lon', dataIndex: 'lon', sorter: true, filterable: true },
    { title: 'Locale', dataIndex: 'locale', sorter: true, filterable: true },
    { title: 'Time Zone', dataIndex: 'timezone', sorter: true, filterable: true },
    { title: 'Data Source', dataIndex: 'datasource', sorter: true, filterable: true },
    { title: 'Trace Config', dataIndex: 'trace_config', sorter: true, filterable: true },
    { key: 'edit', render: (text, record) => (
      <Button type="link" onClick={() => setModalEdit(record)}>
          <EditOutlined/>
      </Button>)
    },
    { key: 'attach', render: (text, record) => (
      <Button type="link" onClick={() => setModalAttach(record)}>
          <PaperClipOutlined />
      </Button>)
    },
    { key: 'del', width: 10,
      render: (text, record) => (
         <Popconfirm title={"Are you sure you want to delete this account?"}
           onConfirm={() => doDelete(record.id)}
           okText="Yes, really delete"
           cancelText="No, cancel"
           okType="danger">
             <Button type="link"><DeleteOutlined/></Button>
         </Popconfirm>
      )
    }
  ];
  return (
    <div>
      Tenant: <Select
        value={tenant == null ? undefined : tenant.name}
        onChange={val => {
          setTenant(tenants.find(x => x.id == val));
          setDataVer(dataVer+1);
        }}
        style={{minWidth: '20ch'}}>
          {tenants.map(t => <Option key={t.id}>{t.name}</Option>)}
      </Select>
      <ManagedPagedTable
        columns={columns}
        rowKey={(rec) => `${rec.tenant}/${rec.external_id}`}
        apiGet={filteredGetAccounts}
        dataVer={dataVer}
      />
      <AddAccount
        onOk={doAdd}
        onCancel={() => setModalAdd(false)}
        visible={modalAdd}
        confirmLoading={working}
      />
      <EditAccount
        onOk={doUpdate}
        onCancel={() => setModalEdit(null)}
        visible={modalEdit != null}
        record={modalEdit || {}}
        confirmLoading={working}
      />
      <DeviceBinding
        onOk={() => setModalAttach(null)}
        visible={modalAttach != null}
        record={modalAttach || {}}
      />
      <Button type="primary" onClick={() => setModalAdd(true)}>Add...</Button>
    </div>
  );
}

export default AccountsPanel;
