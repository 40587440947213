import React, { useState } from 'react';
import { apiLogin } from '../api/api';

const AuthContext = React.createContext();

const useAuth = () => React.useContext(AuthContext);

const AuthProvider = (props) => {
  const [ jwt, setJwt ] = useState(null);
  const [ username, setUsername ] = useState(null);

  async function login(user, pass) {
    const { jwt } = await apiLogin(user, pass);
    setJwt(jwt);
    setUsername(user);
  }

  function logout() {
    setJwt(null);
    setUsername(null);
  }

  function logoutIf401(x) {
    if (x == 401)
      logout();
    else
      throw x;
  }

  return (
    <AuthContext.Provider
      value={{jwt, username, login, logout, logoutIf401 }}
      {...props}
    />
  );
};

export { useAuth, AuthProvider };
