import React from 'react';
import { Menu } from 'antd';

const Nav = (props) => (
  <Menu
    collapsible="false"
    defaultSelectedKeys={[props.defaultItem]}
    className="nav"
    onSelect={props.onSelect}
    theme="light"
  >
    {props.items.map((item, idx) =>
      <Menu.Item key={idx}>{item}</Menu.Item>)}
  </Menu>
);

export default Nav;
