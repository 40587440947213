import React, { useState } from 'react';
import { Spin, Tabs, message } from 'antd';
import ImportSingle from './ImportSingle';
import ImportManifest from './ImportManifest';
import { useAuth } from '../context/auth';
import { apiImportDevices } from '../api/api';

const TabPane = Tabs.TabPane;

const ImportPanel = (props) => {
  const [ importing, setImporting ] = useState(false);
  const [ failed, setFailed ] = useState([]);

  const { jwt, logoutIf401 } = useAuth();

  function onImport(devices) {
    setImporting(true);
    apiImportDevices(jwt, devices)
    .then(res => {
      if (res.failed.length == 0)
        message.info('Successfully imported all devices');
      else
        message.warning('Not all devices were imported');
      setFailed(res.failed);
    })
    .catch(logoutIf401)
    .catch(err => message.error(`Error importing devices: ${err}`))
    .finally(() => {
      setImporting(false);
    });
  }

  return (
  <Spin spinning={importing}>
    <Tabs>
      <TabPane tab="Single Device" key="1">
        <ImportSingle onImport={onImport}/>
      </TabPane>
      <TabPane tab="CSV Manifest" key="2">
        <ImportManifest onImport={onImport} failed={failed}/>
      </TabPane>
    </Tabs>
  </Spin>
  );
}

export default ImportPanel;
