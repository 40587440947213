import React, { useEffect, useState } from 'react';
import { Button,Input, Modal, Popconfirm, Select, Table, message } from 'antd';
import { Form } from '@ant-design/compatible';
import {DeleteOutlined}  from '@ant-design/icons';
import WithModalFormChecking from './WithModalFormChecking';
import { useAuth } from '../context/auth';
import { apiGetRoles, apiAttachedToAccount, apiAttachToAccount, apiDetachFromAccount } from '../api/api';

const AttachDevice = Form.create({ name: 'attach-device' })(
  WithModalFormChecking([ 'did', 'role' ])(
  (props) => {
    const { getFieldDecorator } = props.form;
    return (
      <Form layout="inline">
        <Form.Item label="Device ID">
          {getFieldDecorator('did', {
            rules: [{ required: true }]
          })(<Input style={{width: '14ch'}}/>)}
        </Form.Item>
        <Form.Item label="Role">
          {getFieldDecorator('role', {
            rules: [{ required: true }]
          })(<Select style={{width: '14ch'}}>
              {props.roles.map(r =>
                <Select.Option key={r}>{r}</Select.Option>)}
          </Select>)}
        </Form.Item>
        <Form.Item>
          <Button
            {...props.okButtonProps}
            type="primary"
            onClick={props.onOk}
          >Add</Button>
        </Form.Item>
      </Form>
    );
  })
);


// expects props.record = { tenant, external_id }
const DeviceBinding = (props) => {
  const { record, ...passProps } = props;

  const { jwt, logoutIf401 } = useAuth();

  const [ loading, setLoading ] = useState(true);
  const [ attached, setAttached ] = useState([]);
  const [ roles, setRoles ] = useState([]);
  const [ dataVer, setDataVer ] = useState(0);

  // While it looks like we could call both apiGetRoles and apiAttachedToAccount
  // from within one hook, we don't want to reload the roles on attach/detach,
  // hence the use of two separate useEffect calls.
  useEffect(() => {
    if (record == null || record.tenant == null || record.external_id == null)
      return;
    apiGetRoles(jwt, record)
    .then(res => {
      setRoles(res.entries.map(o => o.name));
    })
    .catch(logoutIf401)
    .catch(err => message.error(`Failed to load roles: ${err}`));
  }, [ jwt, record ]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (record == null || record.tenant == null || record.external_id == null)
      return;
    setLoading(true);
    apiAttachedToAccount(jwt, record)
    .then(res => {
      setAttached(res.entries);
    })
    .catch(logoutIf401)
    .catch(err => message.error(`Failed to load devices: ${err}`))
    .finally(() => {
      setLoading(false);
    });
  }, [ jwt, record, dataVer ]); // eslint-disable-line react-hooks/exhaustive-deps

  function onClose() {
    setAttached([]); // force clear list of devices
    props.onOk();
  }

  function attachDevice(vals) {
    const { did, role } = vals;
    apiAttachToAccount(jwt, record, did, role)
    .then(() => setDataVer(dataVer + 1))
    .catch(logoutIf401)
    .catch(err => message.error(`Failed to attach device: ${err}`));
  }

  function detachDevice(dev_record) {
    const { did } = dev_record;
    apiDetachFromAccount(jwt, record, did)
    .then(() => setDataVer(dataVer + 1))
    .catch(logoutIf401)
    .catch(err => message.error(`Failed to detach device: ${err}`));
  }

  const cols = [
    { title: 'Device ID', dataIndex: 'did' },
    { title: 'Role', dataIndex: 'role' },
    { key: 'detach', render: (text, dev_record) => (
      <Popconfirm
        title="Really detach this device?"
        onConfirm={() => detachDevice(dev_record)}
        okText="Detach"
        cancelText="Cancel"
      >
        <Button type="link"><DeleteOutlined/></Button>
      </Popconfirm>
    )}
  ];

  return (
    <Modal
      { ...passProps }
      title={`Devices for ${(props.record || {}).external_id}`}
      closable={false}
      destroyOnClose={true}
      footer={<Button type="default" onClick={onClose}>Close</Button>}
    >
      <Table
        columns={cols}
        dataSource={attached}
        rowKey="did"
        loading={loading}
        locale={{emptyText: 'No devices attached yet'}}
      />
      <AttachDevice roles={roles} onOk={attachDevice}/>
    </Modal>
  );
}

export default DeviceBinding;
