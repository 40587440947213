import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import { Button, Input, Layout, message } from 'antd';
import {LoadingOutlined, UserOutlined, LockOutlined, LoginOutlined}  from '@ant-design/icons';
import PageHeader from './PageHeader';
import { useAuth } from '../context/auth';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class LoginForm extends React.Component {
  componentDidMount() {
    this.props.form.validateFields();
  }

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } =
      this.props.form;
    const userError = isFieldTouched('username') && getFieldError('username');
    const passError = isFieldTouched('password') && getFieldError('password');
    const defs = { required: true, whitespace: true };
    return (
      <Form onSubmit={this.props.onSubmit} className="login-form">
        <Form.Item
          validateStatus={userError ? 'error' : ''}
          help={userError || ''}
          hasFeedback={true}
          >
          { getFieldDecorator('username', { rules:
            [ { message: 'Please enter username', ...defs } ] }
          )(
            <Input
              prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
              placeholder="username"
            />
          )}
        </Form.Item>
        <Form.Item
          validateStatus={passError ? 'error' : ''}
          help={passError || ''}
          hasFeedback={true}
          >
          { getFieldDecorator('password', { rules:
            [ { message: 'Please enter a pass phrase', ...defs } ] }
          )(
            <Input.Password
              prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
              placeholder="pass phrase"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError())}
          >
            Log in {this.props.iconType === 'login' ? <LoginOutlined/> : <LoadingOutlined />}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const FormWrap = (props) => {
  const auth = useAuth();
  const [ iconType, setIconType ] = useState('login');
  const onSubmit = (e) => {
    e.preventDefault();
    const fields = [ 'username', 'password' ];
    const vals = props.form.getFieldsValue(fields);
    props.form.resetFields(fields);
    props.form.validateFields();
    setIconType('loading');
    auth.login(vals.username, vals.password)
    .catch(err => {
      setIconType('login');
      message.error('Authentication failed.');
    });
  }
  return <LoginForm onSubmit={onSubmit} iconType={iconType} {...props}/>
}

const ActualForm = Form.create({ name: 'login' })(FormWrap);

const Login = (props) => (
  <Layout className="viewport-fullheight">
    <Layout.Header><PageHeader/></Layout.Header>
    <Layout.Content>
      <ActualForm {...props}/>
    </Layout.Content>
  </Layout>
)

export default Login;
