import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useAuth } from '../context/auth';

const PageHeader = (props) => {
  const { logout, username } = useAuth();
  return (
    <div className="top-banner">
      Admin Console
      { username == null ? undefined :
        <div className="username">
          {username}<br/>
          <Button type="link" onClick={logout}>
            Log out <LogoutOutlined type="logout" style={{marginLeft: '1em'}}/>
          </Button>
        </div>
      }
    </div>
  )
}

export default PageHeader;
