import React, { useEffect, useState } from 'react';
import { Button, Input, Spin, message } from 'antd';
import { Form } from '@ant-design/compatible';
import ReactFileReader from 'react-file-reader';
import WithModalFormChecking from './WithModalFormChecking';

const TextArea = Input.TextArea;

function isValidDataFormat(x) {
  return [ 'sensor6', 'calibrator1', 'power', 'energy_Ws', 'energy_kWh' ].indexOf(x) != -1;
}

function isValidGranularity(x) {
  return [ '5min', '30min', 'hour', 'day' ].indexOf(x) != -1;
}

function toCSV(devlist) {
  return devlist.map(d => `${d.did},${d.shared_secret},${d.datafmt},${d.granularity}`).join('\n');
}

const ImportManifest = Form.create({ name: 'import-single' })(
  WithModalFormChecking([])(
  (props) => {
    const [ reading, setReading ] = useState(false);

    const { getFieldDecorator, getFieldValue, setFieldsValue, validateFields } = props.form;

    const { failed } = props;

    useEffect(() => {
      setFieldsValue({ manifest: toCSV(failed) });
    }, [ failed ]); // eslint-disable-line react-hooks/exhaustive-deps

    function parseCSV() {
      const csv = getFieldValue('manifest');
      try {
        const devs = csv.split('\n').reduce((arr, line) => {
          if (line.length > 0) {
            const [ did, shared_secret, datafmt, granularity ] = line.split(',');
            if (granularity == null)
              throw `Wrong number of fields at: ${line}`; // eslint-disable-line
            if (did.length == 0)
              throw `Missing device ID at: ${line}`; // eslint-disable-line
            if (shared_secret.length != 64)
              throw `Incorrect key length for device ${did}`; // eslint-disable-line
            if (!isValidDataFormat(datafmt))
              throw `Unrecognised data format: ${dataformat}`; // eslint-disable-line
            if (!isValidGranularity(granularity))
              throw `Unrecognised granularity: ${granularity}`; // eslint-disable-line
            arr.push({ did, shared_secret, datafmt, granularity });
          }
          return arr;
        }, []);
        props.onImport(devs);
      }
      catch(e) {
        message.error(''+e);
      }
    }

    function loadFile(files) {
      setReading(true);
      const reader = new FileReader();
      reader.onload = (err) => {
        setFieldsValue({ manifest: reader.result });
        setReading(false);
        validateFields();
      }
      reader.readAsText(files[0]);
    }

    return (
      <div>
        <ReactFileReader handleFiles={loadFile} fileTypes=".csv">
           <Button type="default" className="float-right">Load from file...</Button>
        </ReactFileReader>
        Paste or load manifest here:
        <Form layout="vertical">
          <Spin spinning={reading}>
            <Form.Item label="">
              {getFieldDecorator('manifest', {
                rules: [{ required: true }]
              })(<TextArea rows={10} name="manifest" placeholder="#device id,shared key,data format,granularity&#10;1234567890,0123456789abcdef0123456789abcdef0123456789abcdef0123456789abcdef,sensor6,5min"/>)}
            </Form.Item>
            <Form.Item>
              <Button
                {...props.okButtonProps}
                type="primary"
                onClick={parseCSV}
              >Import</Button>
            </Form.Item>
          </Spin>
        </Form>
      </div>
    );
  })
);

export default ImportManifest;
