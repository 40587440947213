import React from 'react';
import './App.css';
import Login from './components/Login';
import Main from './components/Main';
import { useAuth } from './context/auth';
import 'antd/dist/reset.css';

function App() {
  const auth = useAuth();
  return auth.jwt == null ? <Login/> : <Main/>;
}

export default App;
