import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { useAuth } from './auth';
import { apiGetTenants } from '../api/api';

const TenantsContext = React.createContext();

const useTenants = () => React.useContext(TenantsContext);

const TenantsProvider = (props) => {
  const [ tenants, setTenants ] = useState([]);

  const { jwt, logoutIf401 } = useAuth();

  async function reloadTenants() {
    if (jwt == null)
      return;
    apiGetTenants(jwt, 'pagesize=1000')
    .then(res => setTenants(res.entries.reduce((obj, e) => {
      obj[e.id] = e;
      return obj;
    }, {})))
    .catch(logoutIf401)
    .catch(err => message.warning(`Failed to load tenants list: ${err}`));
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { reloadTenants() }, [ jwt ]);

  return (
    <TenantsContext.Provider
      {...props}
      value={{ tenants, reloadTenants }}
    />
  );
}

export { useTenants, TenantsProvider };
