import React from 'react';

const WithModalFormChecking = (fields, assumeAllTouched) => (BaseModal) => (props) => {
  const { onOk, ...remainingProps } = props;
  const { getFieldsValue, getFieldsError, isFieldTouched, resetFields } = props.form;

  function hasErrors() {
    const fieldsError = getFieldsError();
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }

  function allTouched() {
    return fields.reduce((touched, f) => touched && isFieldTouched(f), true);
  }

  function handleOk() {
    const vals = getFieldsValue();
    resetFields();
    onOk(vals);
  }

  return <BaseModal
    {...remainingProps}
    onOk={handleOk}
    okButtonProps={{disabled: !(allTouched() || assumeAllTouched) || hasErrors()}}
  />;
}

export default WithModalFormChecking;
