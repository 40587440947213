import React, { useState } from 'react';
import { message } from 'antd';
import PagedTable from './PagedTable';
import { useAuth } from '../context/auth';

// expects props.apiGet(jwt, qs) to be provided, in addition to table props
// props.dataVer can be changed to trigger a page reload
const ManagedPagedTable = (props) => {
  const { jwt, logoutIf401 } = useAuth();

  const [ pageData, setPageData ] = useState({});
  const [ loading, setLoading ] = useState(true);
  const [ lastQs, setLastQs ] = useState(null);

  function onPageRequest(qs) {
    setLoading(true);
    setLastQs(qs);
    props.apiGet(jwt, qs)
    .then(setPageData)
    .catch(logoutIf401)
    .catch(err => {
      message.warning(`Failed to load page data: ${err}`);
    })
    .then(() => setLoading(false));
  }

  const [ lastVer, setLastVer ] = useState(props.dataVer);

  // Has the data (possibly) changed from the outside? Reload page data if so
  if (props.dataVer != lastVer) {
    setLastVer(props.dataVer);
    onPageRequest(lastQs);
  }

  return (
    <PagedTable
      {...props}
      loading={loading}
      onPageRequest={onPageRequest}
      dataSource={pageData}
      scroll={{x: 'max-content'}}
    />
  );
}

export default ManagedPagedTable;
