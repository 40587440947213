import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AppProviders from './AppProviders';

if (Promise.prototype.finally == null) {
  // eslint-disable-next-line no-extend-native
  Promise.prototype.finally = function(onFinally) {
    return this.then(
      res => Promise.resolve(onFinally()).then(() => res),
      err => Promise.resolve(onFinally()).then(() => { throw err; })
    );
  };
}

ReactDOM.render(
  <AppProviders>
    <App/>
  </AppProviders>,
  document.getElementById('root'));
