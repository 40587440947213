import React from 'react';
import { Input, Table } from 'antd';

const defSizeOpts = [ '25', '50', '100', '200', '500' ];

class PagedTable extends React.Component {
  componentDidMount = () => {
    this.loadPage(1);
  }

  componentDidUpdate = () => {
    if (this.props.dataSource.total !== this.state.total) {
      this.setState(Object.assign({}, this.state, {
          total: this.props.dataSource.total
      }));
    }
  }

  loadPage = (pageNo) => {
    const pageSize = this.state.pagination.pageSize ||
      (this.props.pageSizeOptions || defSizeOpts)[0];
    const order_field = this.state.sort.field;
    const order_dir =
      this.state.sort.order === 'ascend' ? 'asc' :
      this.state.sort.order === 'descend' ? 'desc' : null;
    const filter_keys = Object.keys(this.state.filters);
    const filters = filter_keys.length ?
      filter_keys.map(k => `${k}:${this.state.filters[k]}`).join(',')
      : null;
    this.props.onPageRequest(
      [
        `pagesize=${pageSize}`,
        `pageno=${pageNo-1}`,
        (order_field && order_dir) ?
          `order=${order_field} ${order_dir}` : '',
        (filters) ? `filter=${filters}` : '',
      ].filter(x => x !== '').join('&')
    );
  }

  onChange = (pagination, local_filters, sort, extra) => {
    this.setState(Object.assign({}, this.state, {
      pagination, sort
    }), () => {
      this.loadPage(pagination.current);
    });
  }

  prepareFilter = (col) => {
    const filter = this.state.filters[col.dataIndex];
    const filteredValue = (filter != null && filter != '') ? 'x' : null;
    // Returning a different object (instead of col) results in a broken sort
    return Object.assign(/*{},*/ col, {
      filterDropdown: col.filterable ? <div className="custom-filter-dropdown">
        <Input
          ref={node => { this.filterInput = node; }}
          placeholder="search"
          name={col.dataIndex}
          onPressEnter={this.onFilterEnter}
          // TODO: filter out commas, or rather, filter-in alphanums only
        />
      </div> : undefined,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible)
          setTimeout(() => this.filterInput.select());
      },
      filteredValue,
      children:
        col.children ? col.children.map(this.prepareFilter) : undefined,
    });
  }

  onFilterEnter = (e) => {
    const newFilters = Object.assign({}, this.state.filters, {
      [e.target.name]: e.target.value
    });
    if (e.target.value === '')
      delete(newFilters[e.target.name]);

    this.setState(Object.assign({}, this.state, {
      filters: newFilters,
      pagination: Object.assign({}, this.state.pagination, {
        // Switch back to first page, or might be stranded on empty page
        current: 1
      })
    }), () => {
      this.loadPage(this.state.pagination.current);
    });
  }

  state = {
    pagination: { current: 1 },
    sort: { /* field, order */ },
    filters: {},
    total: 0,
  }

  render() {
    const sizeOpts = this.props.pageSizeOptions || defSizeOpts;
    const pagi = {
      hideOnSinglePage: false,
      showSizeChanger: true,
      defaultPageSize: +sizeOpts[0],
      pageSizeOptions: sizeOpts,
      style: { marginRight: '5em' },
      total: this.state.total,
    };
    return (
      <Table
        {...this.props}
        columns={this.props.columns.map(this.prepareFilter)}
        rowKey={this.props.rowKey}
        loading={this.props.loading}
        dataSource={this.props.dataSource.entries}
        pagination={pagi}
        size="small"
        onChange={this.onChange}
      />
    );
  }
}

export default PagedTable;
