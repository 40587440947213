import React from 'react';
import { AuthProvider } from './context/auth';
import { TenantsProvider } from './context/tenants';

const AppProviders = ({children}) => (
  <AuthProvider>
    <TenantsProvider>
      {children}
    </TenantsProvider>
  </AuthProvider>
);

export default AppProviders;
