import React, { useState } from 'react';
import { Button, Checkbox, Input, Modal, Popconfirm, Select, message } from 'antd';
import { Form } from '@ant-design/compatible';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import ManagedPagedTable from './ManagedPagedTable';
import WithModalFormChecking from './WithModalFormChecking';
import { useAuth } from '../context/auth';
import { useTenants } from '../context/tenants';
import { apiGetApikeys, apiCreateApikey, apiDeleteApikey } from '../api/api';

function tick(bool, record) {
  return bool ? <CheckOutlined /> : undefined;
}

const Option = Select.Option;

const CreateApikey = Form.create({ name: 'create-apikey' })(
  WithModalFormChecking([ 'tenant', 'description', 'cors_domain' ])(
  (props) => {
    const { getFieldDecorator } = props.form;
    const { tenants } = useTenants();

    return (
      <Modal
        { ...props }
        title="Generate API key"
        okText="Create"
      >
        <Form layout="vertical">
          <Form.Item label="Tenant">
            {getFieldDecorator('tenant', {
              rules: [{ required: true }]
            })(<Select>
              { Object.values(tenants).map(x =>
                <Option key={x.id}>{x.name}</Option>)}
            </Select>
            )}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description', {
              rules: [{ required: true }]
            })(<Input placeholder="Enter description for new API key"/>)}
          </Form.Item>
          <Form.Item label="CORS domain">
            {getFieldDecorator('cors_domain', {
              rules: [{ required: true }]
            })(<Input placeholder="*"/>)}
          </Form.Item>
          <Form.Item label="Self-serve">
            {getFieldDecorator('feature_self_serve', {
              rules: [{ required: true }],
              initialValue: false,
              valuePropName: 'checked'
            })(<Checkbox>JWTs signed by this key will all have access to the self-serve functions (own account admin access).</Checkbox>)}
          </Form.Item>
          <Form.Item label="Admin access">
            {getFieldDecorator('feature_admin_account', {
              rules: [{ required: true }],
              initialValue: false,
              valuePropName: 'checked'
            })(<Checkbox>JWTs signed by this key will have admin access to <i>all</i> accounts in the tenancy.</Checkbox>)}
          </Form.Item>
          <Form.Item label="Arbitration access">
            {getFieldDecorator('feature_arbitration', {
              rules: [{ required: true }],
              initialValue: false,
              valuePropName: 'checked'
            })(<Checkbox>JWTs signed by this key will have arbitration access, allowing establishment and renaming of accounts. This type of apikey is intended for use by the global-user-auth service <i>only</i>.</Checkbox>)}
          </Form.Item>
        </Form>
      </Modal>
    );
  })
);


const ApikeysPanel = (props) => {
  const [ dataVer, setDataVer ] = useState(0);
  const [ creating, setCreating ] = useState(false);

  const { jwt, logoutIf401 } = useAuth();
  const { tenants } = useTenants();

  function doDelete(apikey) {
    apiDeleteApikey(jwt, apikey)
    .then(() => {
      message.info(`Key ${apikey} permanently revoked.`);
      setDataVer(dataVer + 1);
    })
    .catch(logoutIf401)
    .catch(err => message.warn(`Failed to delete apikey: ${err}`));
  }

  function doCreate(vals) {
    apiCreateApikey(jwt, vals)
    .then(res => {
      const modal = Modal.success({
        title: "Generated API key",
        centered: true,
        okText: "Close",
        onOk: () => modal.destroy(),
        width: '100ch',
        content: <div>
          The following key/secret pair has been generated:
          <div style={{marginTop: 10}}><b>API key:</b> {res.apikey}</div>
          <div style={{marginBottom: 10}}><b>Shared secret:</b> {res.shared_secret}</div>
          Please save these now - this is the <b>last time</b> the shared secret will be <b>shown</b>.
        </div>
      });
    })
    .catch(logoutIf401)
    .finally(() => {
      setCreating(false);
      setDataVer(dataVer + 1);
    });
  }

  function enhancedGetApikeys(jwt, qs) {
    return apiGetApikeys(jwt, qs)
    .then(res => {
      for (const e of res.entries)
        e.tenant_name = (tenants[e.tenant] || {}).name;
      return res;
    })
    .catch(logoutIf401);
  }

  const cols = [
    { title: 'API Key', dataIndex: 'apikey', sorter: true, filterable: true, fixed: 'left' },
    { title: '', children: [
    { title: 'Tenancy', dataIndex: 'tenant', sorter: true, filterable: true },
    { title: 'Tenant name', dataIndex: 'tenant_name' },
    { title: 'Description', dataIndex: 'description', sorter: true, filterable: true },
    { title: 'CORS domain', dataIndex: 'cors_domain', sorter: true, filterable: true },
    ]},
    { title: 'Features', className: 'left-border', children: [
      { title: 'Self-serve', dataIndex: 'feature_self_serve', sorter: true, filterable: true, className: 'left-border', align: 'center', render: tick },
      { title: 'Admin access', dataIndex: 'feature_admin_account', sorter: true, filterable: true, align: 'center', render: tick },
      { title: 'Arbitration access', dataIndex: 'feature_arbitration', sorter: true, filterable: true, align: 'center', render: tick },
      { title: 'Super admin', dataIndex: 'feature_superadmin', sorter: true, filterable: true, align: 'center', render: tick },
      { title: 'Debug', dataIndex: 'feature_debug', sorter: true, filterable: true, align: 'center', render: tick },
      { title: 'Non-expiring JWT', dataIndex: 'feature_eternal_jwt', sorter: true, filterable: true, align: 'center', render: tick },
    ]},
    { key: 'delete', className: 'left-border', render: (text, record) => (
      <Popconfirm
        title="Permanently revoke this API key?"
        onConfirm={() => doDelete(record.apikey)}
        okText="Yes, revoke it"
        cancelText="No, cancel"
        okType="danger"
      >
        <Button type="link"><DeleteOutlined /></Button>
      </Popconfirm>
    )},
  ];
  return (
    <div>
      <ManagedPagedTable
        className="nobreak"
        columns={cols}
        rowKey="apikey"
        apiGet={enhancedGetApikeys}
        dataVer={dataVer}
      />
      <CreateApikey
        onOk={doCreate}
        onCancel={() => setCreating(false)}
        visible={creating}
      />
      <Button type="primary" onClick={() => setCreating(true)}>Create...</Button>
    </div>
  );
}

export default ApikeysPanel;
