import { Layout } from 'antd';
import React, { useState } from 'react';
import AccountsPanel from './AccountsPanel';
import ApikeysPanel from './ApikeysPanel';
import DevicesPanel from './DevicesPanel';
import ImportPanel from './ImportPanel';
import Nav from './Nav';
import PageHeader from './PageHeader';

const panels = [
  { name: 'API keys', component: <ApikeysPanel/>, },
  { name: 'Accounts', component: <AccountsPanel/>, },
  { name: 'Devices', component: <DevicesPanel/>, },
  { name: 'Import devices', component: <ImportPanel/>, },
];

const Main = (props) => {
  const [ selected, setSelected ] = useState(0);
  const onNav = ({ key }) => { setSelected(key); }
  const { Content, Header, Sider } = Layout;
  return (
    <Layout className="viewport-fullheight">
      <Header><PageHeader/></Header>
      <Layout>
        <Sider>
          <Layout className="fullheight">
            <Content>
              <Nav
                defaultItem="0"
                onSelect={onNav}
                items={panels.map(x => x.name)}
              />
            </Content>
          </Layout>
        </Sider>
        <Content className="content-panel">
          {panels[selected].component}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Main;
