function get_login_url() {

  if (window.location.href.startsWith('https://admin-console.powersensor.com.au/'))
      return 'https://user-auth-svc.prod-1.powersensor.dius.com.au/v1';

  return 'https://user-auth-svc.test-0.powersensor.dius.com.au/v1';
}
const login_url = get_login_url();

var admin_url = null;

function auth_header_obj(jwt) {
  return (jwt == null) ? {} : { 'authorization': `Bearer ${jwt}` };
}


async function expect201(res) {
  if (res.status == 201)
    return null;
  else
    return Promise.reject((await res.json()).message);
}


async function expect204(res) {
  if (res.status == 204)
    return null;
  else
    return Promise.reject((await res.json()).message);
}


async function post(jwt, base_url, path, body) {
  const hdrs = Object.assign(
    auth_header_obj(jwt),
    { 'content-type': 'application/json' }
  );
  return window.fetch(`${base_url}${path}`, {
    method: 'POST',
    headers: hdrs,
    body: JSON.stringify(body)
  })
}


async function put(jwt, base_url, path, body) {
  const hdrs = Object.assign(
    auth_header_obj(jwt),
    { 'content-type': 'application/json' }
  );
  return window.fetch(`${base_url}${path}`, {
    method: 'PUT',
    headers: hdrs,
    body: JSON.stringify(body)
  })
}


async function get(jwt, base_url, path) {
  const res = await window.fetch(`${base_url}${path}`, {
    headers: auth_header_obj(jwt)
  });
  if (res.status == 200)
    return res.json();
  else if (res.status > 200 && res.status < 300)
    return res.status;
  else
    return Promise.reject(res.status);
}


async function del(jwt, base_url, path) {
  return window.fetch(`${base_url}${path}`, {
    method: 'DELETE',
    headers: auth_header_obj(jwt),
  })
}


export async function apiLogin(username, password) {
  return post(null, login_url, '/auth/admin-console', { username, password })
  .then(async res => {
    if (res.status != 200)
      return Promise.reject('Authentication failed');
    else {
      const body = await res.json();
      admin_url = body.admin_url + '/v2';
      return body;
    }
  });
}


export async function apiGetTenants(jwt, qs) {
  const have_qs = qs != null;
  const extra = have_qs ? '?' + qs : '';
  return get(jwt, admin_url, `/tenant${extra}`);
}


export async function apiRemoveTenant(jwt, id) {
  return del(jwt, admin_url, `/tenant/${id}`)
  .then(expect204);
}


export async function apiCreateTenant(jwt, name) {
  return post(jwt, admin_url, '/tenant', { name })
  .then(expect201);
}


export async function apiGetAccounts(jwt, qs) {
  const have_qs = qs != null;
  const extra = have_qs ? '?' + qs : '';
  return get(jwt, admin_url, `/account${extra}`)
  .then(obj => {
     obj.entries.forEach(e => {
       e.external_id = e.account;
       if (e.location != null) {
         e.lat = e.location.lat;
         e.lon = e.location.lon;
       }
     });
     return obj;
  });
}


// record: { tenant, external_id, locale, timezone, [lat, lon ] }
export async function apiCreateAccount(jwt, record) {
  return post(jwt, admin_url, '/account/create', record)
  .then(res => {
    if (res.status === 201 || res.status === 409) {
      if (record.lat != null && record.lon != null)
        return apiUpdateAccount(jwt, record);
      else
        return null;
    }
    else
      throw new Error(`server reported ${res.status}`);
  });
}


export async function apiUpdateAccount(jwt, record) {
  const path = `/account/settings?tenant=${record.tenant}&account=${record.external_id}`;
  const formatted = Object.assign({}, record,
    { location: { lat: record.lat, lon: record.lon }},
    { lat: undefined, lon: undefined });
  return put(jwt, admin_url, path, formatted)
  .then(expect204);
}


export async function apiAttachedToAccount(jwt, record) {
  const path = `/account/device?tenant=${record.tenant}&account=${record.external_id}`;
  return get(jwt, admin_url, path);
}


export async function apiAttachToAccount(jwt, record, did, role) {
  const path = `/account/device/${did}/bind?tenant=${record.tenant}&account=${record.external_id}`;
  return post(jwt, admin_url, path, { role })
  .then(expect204);
}


export async function apiDetachFromAccount(jwt, record, did) {
  const path = `/account/device/${did}?tenant=${record.tenant}&account=${record.external_id}`;
  return del(jwt, admin_url, path)
  .then(expect204);
}


export async function apiGetRoles(jwt, record) {
  const path = `/account/devicerole?tenant=${record.tenant}&account=${record.external_id}&pagesize=100`;
  return get(jwt, admin_url, path);
}


export async function apiGetDevices(jwt, qs) {
  const have_qs = qs != null;
  const extra = have_qs ? '?' + qs : '';
  return get(jwt, admin_url, `/device${extra}`);
}


export async function apiUpdateDevice(jwt, did, changes) {
  return put(jwt, admin_url, `/device/${did}`, changes);
}


export async function apiImportDevices(jwt, devices) {
  return post(jwt, admin_url, '/device', { manifest: devices })
  .then(res => {
    if (res.status == 201)
      return res.json();
    else
      return Promise.reject(res.status);
  });
}


export async function apiDeleteDevice(jwt, did) {
  return del(jwt, admin_url, `/device/${did}`)
  .then(expect204);
}


export async function apiGetDiagnosticsForDevice(jwt, did) {
  return get(jwt, admin_url, `/device/${did}/diagnostics`);
}


export async function apiGetApikeys(jwt, qs) {
  const have_qs = qs != null;
  const extra = have_qs ? '?' + qs : '';
  return get(jwt, admin_url, `/apikey${extra}`);
}


// info: { tenant, description, cors_domain, feature_self_serve, feature_admin_account }
export async function apiCreateApikey(jwt, info) {
  return post(jwt, admin_url, '/apikey', info)
  .then(res => {
    if (res.status === 201)
      return res.json();
    else
      return Promise.reject(res.status);
  });
}


export async function apiDeleteApikey(jwt, apikey) {
  return del(jwt, admin_url, `/apikey/${apikey}`)
  .then(expect204);
}
