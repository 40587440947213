import { Form } from '@ant-design/compatible';
import { DeleteOutlined, EditOutlined, EyeOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Popconfirm, Table, message } from 'antd';
import React, { useState } from 'react';
import { apiDeleteDevice, apiGetDevices, apiGetDiagnosticsForDevice, apiUpdateDevice } from '../api/api';
import { useAuth } from '../context/auth';
import { useTenants } from '../context/tenants';
import DeviceBinding from './DeviceBinding';
import ManagedPagedTable from './ManagedPagedTable';
import WithModalFormChecking from './WithModalFormChecking';

const UpdateDevice = Form.create({ name: 'edit-device' })(
  WithModalFormChecking([])(
  (props) => {
    const { getFieldDecorator } = props.form;
    const record = props.record || {};
    return (
      <Modal
        { ...props }
        title={`Update ${record.did}`}
        okText="Apply"
        destroyOnClose={true}
      >
        <Form layout="vertical">
          <Form.Item label="Wanted version">
            {getFieldDecorator('desired_version', { initialValue: record.desired_version })(
              <Input placeholder="leave empty for no version change"/>)}
          </Form.Item>
          <Form.Item label="Flags">
            {getFieldDecorator('flags', { initialValue: record.flags })(
              <Input placeholder="use 0x prefix for hex"/>)}
          </Form.Item>
          <Form.Item label="Once flags">
            {getFieldDecorator('once_flags', { initialValue: record.once_flags })(
              <Input placeholder="use 0x prefix for hex"/>)}
          </Form.Item>
        </Form>
      </Modal>
    );
  })
);

const DevicesPanel = (props) => {
  const [ attaching, setAttaching ] = useState(null);
  const [ editing, setEditing ] = useState(null);
  const [ updating, setUpdating ] = useState(false);
  const [ dataVer, setDataVer ] = useState(0);
  const [ diagnostics, setDiagnostics ] = useState(null);

  const { jwt, logoutIf401 } = useAuth();
  const { tenants } = useTenants();

  function fetchDiag(did) {
    apiGetDiagnosticsForDevice(jwt, did)
    .then(obj => {
      setDiagnostics(Object.keys(obj).reduce((arr, key) => {
        arr.push(Object.assign({ key }, obj[key]));
        return arr;
      }, []));
    })
    .catch(err => {
      setDiagnostics(null);
      message.error(`Failed to read diagnostics: ${err}`);
    })
  }

  function doUpdate(vals) {
    const changes = [ 'flags', 'once_flags', 'desired_version' ].reduce(
      (obj, key) => {
        if (vals[key] == null || vals[key] == '')
          obj[key] = null;
        else
          obj[key] = +vals[key];
        return obj;
      },
      {}
    );
    // Update the 'editing' record to avoid old values reappearing when we
    // set the 'updating' flag.
    const new_rec = Object.assign({}, editing, changes);
    setEditing(new_rec);
    setUpdating(true);
    apiUpdateDevice(jwt, editing.did, changes)
    .catch(logoutIf401)
    .catch(err => message.error(`Failed to update device: ${err}`))
    .finally(() => {
      setEditing(null);
      setUpdating(false);
      setDataVer(dataVer+1);
    });
  }

  function doDelete(did) {
    apiDeleteDevice(jwt, did)
    .catch(logoutIf401)
    .catch(err => message.error(`Failed to delete device: ${err}`))
    .finally(() => {
      setDataVer(dataVer+1);
    });
  }

  function enhancedGetDevices(jwt, qs) {
    return apiGetDevices(jwt, qs)
    .then(res => {
      for (const e of res.entries)
        e.tenant_name = (tenants[e.tenant] || {}).name;
      return res;
    })
    .catch(logoutIf401);
  }

  const cols = [
      { title: 'Device ID', dataIndex: 'did', sorter: true, filterable: true, fixed: 'left' },
    { title: 'Device properties', children: [
      { title: 'Shared key', dataIndex: 'shared_secret', sorter: true, filterable: true },
      { title: 'Data format', dataIndex: 'datafmt', sorter: true, filterable: true },
      { title: 'Granularity', dataIndex: 'granularity', sorter: true, filterable: true },
      { title: 'Manufactured', dataIndex: 'production_date', sorter: true, filterable: true },
    ]},
    { title: 'Firmware', className: 'left-border', children: [
      { title: 'Reported', dataIndex: 'reported_version', sorter: true, filterable: true, className: 'left-border' },
      { title: 'Wanted', dataIndex: 'desired_version', sorter: true, filterable: true },
    ]},
    { title: 'Flags', className: 'left-border', children: [
      { title: 'Always', dataIndex: 'flags', sorter: true, filterable: true, className: 'left-border' },
      { title: 'Once', dataIndex: 'once_flags', sorter: true, filterable: true },
    ]},
    { title: '', className: 'left-border', children: [
      { key: 'edit', className: 'left-border', render: (text, record) => (
        <Button type="link" onClick={() => setEditing(record)}><EditOutlined/></Button>
      )},
      { key: 'diagnostics', render: (text, record) => (
        <Button type="link" onClick={() => fetchDiag(record.did)}><EyeOutlined /></Button>
      )},
      { key: 'delete', render: (text, record) => (
        <Popconfirm
          title={<div><b>Warning:</b> Deleting a device <b>also deletes all its history</b>!<br/>Really delete {`${record.did}`}?</div>}
          onConfirm={() => doDelete(record.did)}
          okType="danger"
        ><Button type="link"><DeleteOutlined/></Button>
        </Popconfirm>
      )}
    ]},
    { title: 'Current ownership', className: 'double-left-border', children: [
      { title: 'Tenancy', dataIndex: 'tenant', sorter: true, filterable: true, className: 'double-left-border' },
      { title: 'Tenant name', dataIndex: 'tenant_name' },
      { title: 'Owner', dataIndex: 'owner', sorter: true, filterable: true },
      { title: '', key: 'attach', render: (text, record) => (
        (record.owner != null && record.tenant != null) ?
          <Button
            type="link"
            onClick={() => setAttaching({
              tenant: record.tenant, external_id: record.owner })}
          >
            <PaperClipOutlined />
          </Button> : undefined
      )},
    ]},
  ];
  return (
    <div>
      <ManagedPagedTable
        className="nobreak"
        columns={cols}
        rowKey="did"
        apiGet={enhancedGetDevices}
        dataVer={dataVer}
      />

      <UpdateDevice
        visible={editing != null}
        record={editing}
        onOk={doUpdate}
        onCancel={() => setEditing(null)}
        confirmLoading={updating}
      />

      <DeviceBinding
        visible={attaching != null}
        record={attaching}
        onOk={() => { setAttaching(null); setDataVer(dataVer+1); }}
      />

      <Modal
        title="Diagnostics"
        visible={diagnostics != null}
        onOk={() => setDiagnostics(null)}
        onCancel={() => setDiagnostics(null)}
      >
        <Table
          dataSource={diagnostics}
          columns={[
            { title: 'Tag', dataIndex: 'key', sorter: true },
            { title: 'Utc1', dataIndex: 'utc1', sorter: true },
            { title: 'Utc2', dataIndex: 'utc2', sorter: true },
            { title: 'Value', dataIndex: 'value', sorter: true },
          ]}
          size="small"
          scroll={{x: true }}
        />
      </Modal>

    </div>
  );
}

export default DevicesPanel;
